import "liquid-ajax-cart";
import $ from "cash-dom";
import Alpine from "alpinejs";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineWire from "../js/alpine/plugins/wire";
import AlpineGlobals from "../js/alpine/index.js";
import helpers, { hasBodyClass } from "../js/helpers.js";
import throttle from "raf-throttle";
import Swiper from "swiper";
import {
  Navigation,
  Pagination,
  A11y,
  Mousewheel,
  FreeMode,
} from "swiper/modules";
// Dynamic imports
hasBodyClass("product-*") && import("../js/prodify.js");

const ns = "slayed";

window.slayedNamespace = ns;
window[ns] = window[ns] || {};
window[ns].helpers = helpers;

for (const [key, value] of Object.entries(helpers)) {
  window[ns].helpers[key] = value;
}

// Register and initialize AlpineJS
window.Alpine = Alpine;

Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineWire, AlpineMorph]);
AlpineGlobals.register(Alpine);
Alpine.start();

const initSite = () => {
  const windowResizeEvents = () => {
    const root = document.documentElement;

    $("body").append(
      '<div class="resizing-helper" style="position:fixed;top:0;left:0;width:100%;height:100%;z-index:100;visibility:hidden;pointer-events:none;"><div class="container container-inset resizing-helper"><div class="content"></div></div></div>'
    );
    let leftOffset = $(".resizing-helper > .content").offset().left;
    $(".resizing-helper").remove();
    root.style.setProperty("--site-left-edge", `${leftOffset}px`);

    $("[data-expander-parent].open").each(function (idx, elem) {
      const content = $(elem).find("[data-expander-content]").first();
      $(content).css({
        maxHeight: getPotentialMaxHeight(content),
      });
    });
    let headerHeight = 0;
    if ($("header").length) {
      headerHeight = $("header").outerHeight();
    }
    root.style.setProperty("--header-height", `${headerHeight}px`);
    let navHeight = 0;
    if ($("header.bg-yellow").length) {
      navHeight = $("header.bg-yellow").outerHeight();
    }
    root.style.setProperty("--nav-height", `${navHeight}px`);
    if ($(".hero.style--split").length) {
      const hero = $(".hero.style--split"),
        target = hero.find(".container-inset.flex-col"),
        targetHeight = $(target).outerHeight();
      $(hero)[0].style.setProperty("--hero-min-height", `${targetHeight}px`);
    }
    if ($(".announcement-bar .track div").length) {
      const targetWidth = $(".announcement-bar .track")[0].scrollWidth;
      $(".announcement-bar")[0].style.setProperty(
        "--container-width",
        `${targetWidth}px`
      );
    }
  };

  const throttledResize = throttle(windowResizeEvents);
  window.addEventListener("resize", throttledResize);
  window.addEventListener("load", windowResizeEvents);

  const initCarousels = () => {
    $(".carousel").each(function (idx, elem) {
      const parent = $(elem),
        swiperElem = $(elem).find(".carousel-swiper")[0],
        paginationElem = $(elem).find(".swiper-pagination")[0],
        nextButtonElem = $(elem).find(".swiper-button-next")[0],
        prevButtonElem = $(elem).find(".swiper-button-prev")[0];
      const carouselSwiper = new Swiper(swiperElem, {
        slidesPerView: 1,
        spaceBetween: 0,
        modules: [Navigation, Pagination, A11y],
        rewind: false,
        loop: true,
        autoHeight: false,
        pagination: {
          el: paginationElem,
        },

        // Navigation arrows
        navigation: {
          nextEl: nextButtonElem,
          prevEl: prevButtonElem,
        },
      });
    });
  };

  initCarousels();
};
initSite();

// Hide the Shopify preview bar when in development
// if (process.env.NODE_ENV === 'development') {
//   //
//   window.addEventListener('DOMContentLoaded', () => {
//     var css = '#preview-bar-iframe { display: none !important; }',
//       head = document.head || document.getElementsByTagName('head')[0],
//       style = document.createElement('style')

//     head.appendChild(style)

//     style.appendChild(document.createTextNode(css))
//   })
// }
